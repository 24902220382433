<template>
  <div class="row mt-2">
    <div class="col-12">
      <div class="file-dialog">
        <el-dialog
          title="Add document"
          :visible="isDialogOpen"
          @close="closeDialog"
        >
          <label for="select-document-title">Document title </label>
          <input
            v-model.trim="doc.title"
            autocomplete="off"
            class="w-100 doc-title-input"
            maxlength="255"
            type="text"
            placeholder="Enter title of the document you uploading"
          />
          <div class="w-100 mt-4">
            <el-upload
              ref="fileInput"
              accept=".doc,.docx,.otd,.ods,.odp,.pdf,.zip,.rar,.png,.JPEG,.JPG,.SVG"
              :on-change="onFileInputChange"
              :on-remove="onFileRemove"
              class=""
              drag
              name="document"
              action="''"
              :file-list="fileList"
              :auto-upload="false"
              :multiple="false"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em>
              </div>
            </el-upload>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button
              :disabled="!isDocumentValid"
              type="primary"
              class="w-100 py-3"
              v-ripple="'rgba(255, 255, 255, 0.35)'"
              @click="onAddDocument"
              >Add Document</el-button
            >
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isDialogOpen: { type: Boolean, required: true },
  },
  data() {
    return {
      showDialog: false,
      isUploadingDocument: false,
      addedDocuments: [],
      doc: {
        title: "",
        fileSize: null,
        fileName: null,
        file: null,
      },
      fileList: [],
    };
  },
  computed: {},
  methods: {
    onFileRemove() {
      this.doc.fileSize = null;
      this.doc.fileName = null;
      this.doc.file = null;
    },

    closeDialog() {
      this.clearInputs();
      this.$emit("on-close-dialog");
    },

    clearInputs() {
      this.doc.title = "";
      this.doc.fileSize = null;
      this.doc.fileName = null;
      this.doc.file = null;
      this.fileList = [];
    },

    isDocumentValid() {
      if (!this.doc.title) {
        return false;
      }

      if (this.doc.file === null) {
        return false;
      }

      return true;
    },
    onFileInputChange(file) {
      this.$refs.fileInput.clearFiles();
      this.doc.fileName = file.name;
      this.doc.fileSize = file.size;
      this.doc.file = file.raw;
      this.fileList = [file];
    },
    onAddDocument() {
      if (!this.isDocumentValid()) {
        return this.showWarningMessage(
          "Title & File required",
          "Ensure that you have added the document title and selected a file for upload"
        );
      }

      const ALLOWED_FILE_EXTENSIONS = [
        "doc",
        "docx",
        "otd",
        "ods",
        "odp",
        "pdf",
        "zip",
        "rar",
        "png",
        "jpeg",
        "jpg",
        "svg",
      ];

      // Ensure the file type chosen is an image in the allowed file extensions
      const selectedFile = this.doc.file;
      const fileExtension = selectedFile.name?.split(".")?.pop()?.toLowerCase();
      if (!ALLOWED_FILE_EXTENSIONS.includes(fileExtension)) {
        return this.showWarningMessage(
          "Invalid file-Type",
          "Only documents of type doc, docx, otd, ods, odp, pdf, zip, rar, png, jpeg are allowed"
        );
      }

      // Ensure the file is not more than 20MB
      if (this.doc.fileSize > 20 * 1024 * 1024) {
        return this.showWarningMessage(
          "File too large",
          "The file you are trying to upload is too large. Please upload a file less than 20MB"
        );
      }

      // Ensure the file the user is trying to upload is not a shortcut file
      if (fileExtension === "lnk") {
        return this.showWarningMessage(
          "Invalid file-Type",
          "You cannot upload a shortcut file. Please upload a valid file"
        );
      }

      // Ensure the user is not trying to upload an empty file
      if (this.doc.fileSize === 0) {
        return this.showWarningMessage(
          "Invalid file-Type",
          "You cannot upload an empty file. Please upload a valid file"
        );
      }

      this.$emit("on-add-document", {
        ...this.doc,
        _id: +new Date(),
      });
    },
  },
};
</script>
<style>
.file-dialog .el-dialog {
  width: 400px !important;
}
.file-dialog .el-upload-dragger,
.file-dialog .el-upload__tip,
.file-dialog .el-upload-list__item-name {
  width: 360px !important;
}
@media screen and (max-width: 450px) {
  .file-dialog .el-dialog {
    width: 380px !important;
  }
  .file-dialog .el-upload-dragger,
  .file-dialog .el-upload__tip,
  .file-dialog .el-upload-list__item-name {
    width: 340px !important;
  }
}
@media screen and (max-width: 400px) {
  .file-dialog .el-dialog {
    width: 350px !important;
  }
  .file-dialog .el-upload-dragger,
  .file-dialog .el-upload__tip,
  .file-dialog .el-upload-list__item-name {
    width: 310px !important;
  }
}
@media screen and (max-width: 380px) {
  .file-dialog .el-dialog {
    width: 330px !important;
  }
  .file-dialog .el-upload-dragger,
  .file-dialog .el-upload__tip,
  .file-dialog .el-upload-list__item-name {
    width: 290px !important;
  }
}
@media screen and (max-width: 360px) {
  .file-dialog .el-dialog {
    width: 310px !important;
  }
  .file-dialog .el-upload-dragger,
  .file-dialog .el-upload__tip,
  .file-dialog .el-upload-list__item-name {
    width: 270px !important;
  }
}
@media screen and (max-width: 330px) {
  .file-dialog .el-dialog {
    width: 290px !important;
  }
  .file-dialog .el-upload-dragger,
  .file-dialog .el-upload__tip,
  .file-dialog .el-upload-list__item-name {
    width: 250px !important;
  }
}
@media screen and (max-width: 310px) {
  .file-dialog .el-dialog {
    width: 270px !important;
  }
  .file-dialog .el-upload-dragger,
  .file-dialog .el-upload__tip,
  .file-dialog .el-upload-list__item-name {
    width: 230px !important;
  }
}
@media screen and (max-width: 290px) {
  .file-dialog .el-dialog {
    width: 250px !important;
  }
  .file-dialog .el-upload-dragger,
  .file-dialog .el-upload__tip,
  .file-dialog .el-upload-list__item-name {
    width: 210px !important;
  }
}
@media screen and (max-width: 270px) {
  .file-dialog .el-dialog {
    width: 230px !important;
  }
  .file-dialog .el-upload-dragger,
  .file-dialog .el-upload__tip,
  .file-dialog .el-upload-list__item-name {
    width: 190px !important;
  }
}
</style>
<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  background: #fff;
  font-size: 16px;
}
label,
p {
  font-size: 16px;
}
input {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 16px;
}
input:focus {
  outline: none;
}
input::placeholder {
  font-weight: 500;
  font-size: 16px;
  opacity: 0.8;
  color: #ccc;
}
input:disabled {
  background: rgba(0, 0, 0, 0.081);
}
.add-doc-btn {
  font-size: 16px;
  color: #fff;
}
.add-document-btn:hover {
  transition: 0.3s ease;
  transform: scale(0.9);
}
</style>
