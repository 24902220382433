<template>
  <div class="container-fluid my-3 mt-1 mb-5">
    <div class="row">
      <div class="col-12 px-0">
        <h6 style="font-size: 1rem; font-weight: 600">
          <b> Student Official Photo </b>
        </h6>
        <hr />
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-auto pl-0">
        <div class="card-0">
          <div class="row align-items-end">
            <div class="col col-auto text-center">
              <div
                class="id-photo d-flex justify-content-center align-items-center"
              >
                <img v-if="circularImage" :src="circularImage" />
                <i v-else class="el-icon-user-solid"></i>
              </div>
              <small class="label-text my-2">Identity Card Photo</small>
            </div>
            <div class="col col-auto text-center">
              <div
                class="academic-photo d-flex justify-content-center align-items-center"
              >
                <img v-if="squareImage" :src="squareImage" />
                <i v-else class="el-icon-user-solid"></i>
              </div>
              <small class="label-text my-2">Academic Photo</small>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4" v-show="!readOnly">
        <input
          type="file"
          id="file-input"
          class="d-none"
          accept=".jpg,.png,.jpeg"
          @change="onSelectStudentPhotoChange"
          @click="onStudentPhotoInputClick"
        />
        <p style="font-size: 0.9rem">
          Student Photo is required and it should be in png, jpeg, or jpg
          format. You are required to resize the pictures accordingly.
        </p>
        <label
          for="file-input"
          class="btn z-depth-0"
          v-ripple="'rgba(255, 255, 255, 0.35)'"
          style="
            text-transform: capitalize;
            width: 200px;
            height: 45px;
            border-radius: 4px;
            font-size: 12px;
            background-color: var(--el-app-primary);
          "
        >
          Choose Photo
        </label>
      </div>
    </div>
    <el-dialog
      title="Upload Student Photos"
      :visible="isDialogVisible"
      :before-close="onDialogStateChange"
      width="400px"
    >
      <div
        class="w-100 loader-container d-flex justify-content-center align-items-center"
        v-if="isRenderingImage"
      >
        <ScaleOut :background="'#164B70'" />
      </div>
      <div class="cropper-container" v-else>
        <cropper
          v-if="step == 1"
          :src="image"
          @change="onSquareImageChange"
          ref="squareCropper"
          :auto-zoom="true"
          :stencil-size="{
            width: 200,
            height: 200,
          }"
          image-restriction="stencil"
        />
        <cropper
          v-if="step == 2"
          :src="image"
          @change="onCircularImageChange"
          ref="circularCropper"
          :auto-zoom="true"
          :stencil-size="{
            width: 200,
            height: 200,
          }"
          stencil-component="circle-stencil"
        />
      </div>
      <div class="my-2" v-if="!isRenderingImage">
        <el-button
          v-if="step == 1"
          @click="onNextStep"
          align="right"
          type="primary"
        >
          Next</el-button
        >
        <el-button
          v-if="step == 2"
          @click="onDone"
          align="right"
          type="primary"
        >
          Done
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import ScaleOut from "@/components/scale-out-component.vue";
import "vue-advanced-cropper/dist/style.css";
export default {
  name: "UploadStudentOfficialPictureDialogComponent",
  props: [
    "onFinishedCroppingStudentPhotos",
    "existingStudentOfficialPhotos",
    "readOnly",
  ],
  components: {
    Cropper,
    ScaleOut,
  },
  data() {
    return {
      isDialogVisible: false,
      image: "",
      squareImage: "",
      circularImage: "",
      step: 1,
      isRenderingImage: false,
    };
  },

  // computed: {

  //   squareOfficialPhotoURL() {
  //     const squarePhoto = this.officialPhotos.find(
  //       (photo) => photo.is_square_official_picture === true
  //     );
  //     return squarePhoto ? squarePhoto.path_url : "";
  //   },
  // },

  watch: {
    existingStudentOfficialPhotos() {
      if (this.existingStudentOfficialPhotos.length) {
        const circularPhoto = this.existingStudentOfficialPhotos.find(
          (photo) => photo.is_circular_official_picture === true
        );
        this.circularImage = circularPhoto ? circularPhoto.path_url : "";

        const squarePhoto = this.existingStudentOfficialPhotos.find(
          (photo) => photo.is_square_official_picture === true
        );
        this.squareImage = squarePhoto ? squarePhoto.path_url : "";
      }
    },
  },

  created() {
    if (typeof this.existingStudentOfficialPhotos !== "undefined") {
      if (Array.isArray(this.existingStudentOfficialPhotos)) {
        for (
          let index = 0;
          index < this.existingStudentOfficialPhotos.length;
          index++
        ) {
          if (
            this.existingStudentOfficialPhotos[index]
              .is_circular_official_picture == true
          ) {
            this.circularImage =
              this.existingStudentOfficialPhotos[index].path_url;
            continue;
          }
          if (
            this.existingStudentOfficialPhotos[index]
              .is_square_official_picture == true
          ) {
            this.squareImage =
              this.existingStudentOfficialPhotos[index].path_url;
            continue;
          }
        }
      }
    }
  },
  methods: {
    // Do this to make sure change event for the input fires even after choosing same file
    onStudentPhotoInputClick(e) {
      e.target.value = "";
    },

    onSelectStudentPhotoChange(e) {
      e.preventDefault();
      const selectedFile = e.target.files[0];
      // Allow only Photos of type  png, jpg and jpeg.
      const fileType = selectedFile.type;
      const expectedFileTypes = ["png", "jpg", "jpeg"];
      const allowedFileTypes = expectedFileTypes.map((type) => "image/" + type);
      if (allowedFileTypes.indexOf(fileType) === -1) {
        return this.showWarningMessage(
          "Invalid file Type",
          `Only images of type ${expectedFileTypes.toString()} are allowed`
        );
      }
      this.image = "";
      this.isDialogVisible = true;
      this.step = 1;
      // this.circularImage = "";
      // this.squareImage = "";
      this.isRenderingImage = true;
      try {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.image = reader.result;
          this.isRenderingImage = false;
        };
        reader.onerror = () => {
          this.showFailedMessage(
            "Failed to add picture",
            "Check the size and format and try again"
          );
        };
        reader.readAsDataURL(selectedFile);
      } catch (error) {
        //
      } finally {
        //
      }
    },

    onDialogStateChange() {
      this.isDialogVisible = !this.isDialogVisible;
      if (this.circularImage == "" || this.squareImage == "") {
        this.circularImage = "";
        this.squareImage = "";
      }
    },

    // eslint-disable-next-line no-unused-vars
    onSquareImageChange({ canvas }) {
      if (typeof canvas.toDataURL !== "undefined") {
        // this.squareImage = canvas.toDataURL();
      }
    },

    // the circular image
    onCircularImageChange({ canvas }) {
      if (typeof canvas.toDataURL !== "undefined") {
        // this.circularImage = canvas.toDataURL();
      }
    },

    /**
     * Next step
     * after cropping the image
     */
    onNextStep() {
      if (this.step == 1) {
        this.squareImage = this.$refs.squareCropper
          .getResult()
          .canvas.toDataURL();
        this.step = 2;
      }
    },

    // when we are done cropping..
    onDone() {
      if (typeof this.onFinishedCroppingStudentPhotos == "function") {
        this.isRenderingImage = true;
        this.circularImage = this.$refs.circularCropper
          .getResult()
          .canvas.toDataURL();

        this.onFinishedCroppingStudentPhotos({
          circularImage: this.circularImage,
          squareImage: this.squareImage,
        });
        this.isDialogVisible = false;
        this.image = "";
      }
    },
  },
};
</script>

<style>
.id-photo {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  border: dotted var(--el-app-primary) 1px;
  background-color: #f5f5f5;
}

.academic-photo {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  border: dotted var(--el-app-primary) 1px;
  background-color: #f5f5f5;
}

.cropper-container {
  width: 100%;
  height: 200px;
}

.academic-photo img,
.id-photo img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.academic-photo img {
  border-radius: 10px;
}
.id-photo,
.academic-photo {
  font-size: 5rem !important;
  color: #999;
}
.loader-container {
  width: 100%;
  height: 200px;
}
.card-0 {
  width: 100%;
  height: auto;
  border-radius: 10px;
  background: #fefefe;
  padding: 20px;
  border: solid #f5f5f5 1px;
}
</style>
