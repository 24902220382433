/* eslint-disable no-useless-escape */
<template>
  <div class="w-100">
    <div class="container-fluid">
      <div class="row">
        <div class="container text-left mt-5">
          <section class="row">
            <div class="col-12">
              <BreadCrumbComponent :pageTitles="['Online Applications', 'Edit Online Application']" />
              <div
                class="mt-4"
                style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                  margin-bottom: 15px;
                "
              >
                <strong>Edit Online Applications</strong>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <div
      class="container-fluid"
      v-if="!pageDoesNotExist"
    >
      <div
        class="row"
        v-loading="isBusy"
      >
        <div
          class="container text-left"
          v-if="!isAdmitted"
        >
          <UploadStudentOfficialPictureDialogComponent
            :onFinishedCroppingStudentPhotos="onFinishedCroppingStudentPhotos"
            :existingStudentOfficialPhotos="existingStudentOfficialPhotos"
          />
          <!-- Basic information    -->
          <section class="row">
            <div class="col-12">
              <h4>Basic Information</h4>
              <hr class="my-2" />
              <div class="row mt-3 mb-3">
                <div class="col-12 col-md-4 mb-3">
                  <label for="intake-batch">Course Intake Batch*</label><br />
                  <el-select
                    ref="intakeBatchInput"
                    id="intake-batch"
                    v-model="intakeBatchId"
                    :disabled="isBusy"
                    filterable
                    remote
                    placeholder="Enter an intake batch"
                    class="w-100"
                    :remote-method="onSearchCourseIntakeBatches"
                    :loading="isSearching"
                  >
                    <el-option
                      v-for="intakeBatch in courseIntakeBatches"
                      :key="intakeBatch.course_intake_batch_id"
                      :label="intakeBatch.intake"
                      :value="intakeBatch.course_intake_batch_id"
                    >
                      {{ intakeBatch.intake }}
                    </el-option>
                  </el-select>
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="title">Title*</label>
                  <input
                    ref="titleInput"
                    v-model="title"
                    type="text"
                    id="title"
                    placeholder="Mr/Ms/Mrs"
                    class=""
                  />
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="title">First Name*</label>
                  <input
                    ref="firstNameInput"
                    v-model.trim="firstName"
                    type="text"
                    id="title"
                    class=""
                  />
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="title">Last Name*</label>
                  <input
                    ref="lastNameInput"
                    v-model.trim="lastName"
                    type="text"
                    id="title"
                    class=""
                  />
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="title">Gender*</label>
                  <el-select
                    id="gender"
                    ref="genderInput"
                    v-model="gender"
                    placeholder="Select Gender"
                    class="w-100"
                  >
                    <el-option
                      label="Male"
                      value="MALE"
                    />
                    <el-option
                      label="Female"
                      value="FEMALE"
                    />
                  </el-select>
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="nationality">Nationality*</label>
                  <el-select
                    ref="nationalityInput"
                    id="nationality"
                    v-model="nationality"
                    filterable
                    placeholder="Nationality"
                    class="w-100"
                  >
                    <el-option
                      value=""
                      label=""
                    >
                      Select nationality
                    </el-option>
                    <el-option
                      v-for="(nationality, index) in nationalities"
                      :key="index"
                      :label="nationality"
                      :value="nationality"
                    >
                      {{ nationality }}
                    </el-option>
                  </el-select>
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label
                    for="birth-date"
                    class="w-100"
                  >Birth Date*</label>
                  <el-date-picker
                    class="w-100"
                    ref="dobInput"
                    id="birth-date"
                    format="d MMMM yyyy"
                    v-model="dob"
                    type="date"
                    placeholder="Select Birth Date"
                  >
                  </el-date-picker>
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="phone-number">Phone No*</label>
                  <input
                    v-model="phoneNumber"
                    ref="phoneNumberInput"
                    type="text"
                    id="phone-number"
                    placeholder=""
                    class=""
                  />
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="email">Email*</label>
                  <input
                    v-model="email"
                    ref="emailInput"
                    type="email"
                    id="email"
                    placeholder=""
                    class=""
                  />
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="billing-system">Billing System*</label>
                  <el-select
                    ref="billingSystemInput"
                    v-model="billingSystem"
                    placeholder="Select billing system"
                    class="w-100"
                  >
                    <el-option
                      :key="system.billing_system_id"
                      v-for="system in billingSystems"
                      :value="system.billing_system_id"
                      :label="system.name"
                    >
                      {{ system.name }}
                    </el-option>
                  </el-select>
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="entryTypes">Entry Types</label>
                  <el-select
                    ref="entryTypesInput"
                    v-model="entryMethod"
                    placeholder="Select Entry type"
                    class="w-100"
                  >
                    <el-option
                      v-for="type in entryTypes"
                      :key="type.value"
                      :value="type.value"
                      :label="type.label"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="billing-system">Session*</label>
                  <el-select
                    ref="sessionIdInput"
                    v-model="sessionId"
                    placeholder="Select Session"
                    class="w-100"
                  >
                    <el-option
                      v-for="session in sessions"
                      :key="session.student_session_id"
                      :value="session.student_session_id"
                      :label="session.title"
                    >
                      {{ session.title }}
                    </el-option>
                  </el-select>
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="sponsor">Sponsor Name</label>
                  <input
                    ref="sponsorNameInput"
                    v-model="sponsorName"
                    type="text"
                    id="sponsor"
                    placeholder=""
                    class=""
                  />
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="sponsorContact">Sponsor Contact</label>
                  <input
                    ref="sponsorContactInput"
                    v-model="sponsorContact"
                    type="text"
                    id="sponsor-contact"
                    placeholder=""
                    class=""
                  />
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="academic-year">Academic Year*</label>
                  <el-select
                    id="academic-year"
                    ref="academicYearInput"
                    v-model="academicYear"
                    placeholder="Select Entry type"
                    class="w-100"
                  >
                    <el-option
                      v-for="year in academicYears"
                      :key="year.academic_year_configuration_id"
                      :value="year.academic_year_configuration_id"
                      :label="
                        year.academic_year + ' ' + toMonthName(year.month)
                      "
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </section>

          <!-- Bio data -->
          <section class="row mt-2">
            <div class="col-12">
              <h4>Bio Data</h4>
              <hr />
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-12 col-md-4 mb-3">
                  <label for="religion">Religion*</label>
                  <input
                    ref="religionInput"
                    v-model="religion"
                    type="text"
                    id="religion"
                    placeholder=""
                    class=""
                  />
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="marital-status">Marital Status*</label>
                  <el-select
                    ref="maritalStatusInput"
                    id="marital-status"
                    v-model="maritalStatus"
                    placeholder="Marital Status"
                    style="height: 44px; margin-top: 4px"
                    class="w-100"
                  >
                    <el-option
                      label="Married"
                      value="Married"
                    >
                      Married
                    </el-option>
                    <el-option
                      label="Single"
                      value="Single"
                    >
                      Single
                    </el-option>
                  </el-select>
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="spouse-name">Spouse Name</label>
                  <input
                    ref="spouseNameInput"
                    v-model="spouseName"
                    type="text"
                    id="spouse-name"
                    placeholder=""
                    class=""
                    :disabled="isSingle"
                  />
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="spouse-occupation">Spouse Occupation</label>
                  <input
                    ref="spouseOccupationInput"
                    type="text"
                    id="spouse-occupation"
                    v-model="spouseOccupation"
                    class=""
                    :disabled="isSingle"
                  />
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="spouse-contacts">Spouse Contacts</label>
                  <input
                    ref="spouseContactsInput"
                    v-model="spouseContacts"
                    type="text"
                    id="spouse-contacts"
                    placeholder=""
                    class=""
                    :disabled="isSingle"
                  />
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="disabilities">Disabilities</label>
                  <input
                    ref="disabilitiesInput"
                    v-model="disabilities"
                    type="text"
                    id="disabilities"
                    placeholder=""
                    class=""
                  />
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="physical-address">Physical Address</label>
                  <input
                    ref="physicalAddressInput"
                    v-model="physicalAddress"
                    type="text"
                    id="physical-address"
                    placeholder=""
                    class=""
                  />
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="spouse-name">Residence country</label>
                  <el-select
                    ref="residenceCountryInput"
                    id="residence-country"
                    v-model="residenceCountry"
                    filterable
                    placeholder="Residence country"
                    class="w-100"
                  >
                    <el-option
                      value=""
                      label=""
                    > Select Country </el-option>
                    <el-option
                      v-for="(country, index) in countries"
                      :key="index"
                      :label="country[Object.keys(country)[0]]"
                      :value="country[Object.keys(country)[0]]"
                    >
                      {{ country[Object.keys(country)[0]] }}
                    </el-option>
                  </el-select>
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="home-district">Home District / State</label>
                  <input
                    ref="homeDistrictInput"
                    v-model="homeDistrict"
                    type="text"
                    id="home-district"
                    placeholder=""
                    class=""
                  />
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="occupation">Occupation</label>
                  <input
                    ref="occupationInput"
                    v-model="occupation"
                    type="text"
                    id="occupation"
                    placeholder=""
                    class=""
                  />
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="next-of-kin">Next of Kin*</label>
                  <input
                    ref="nextOfKinInput"
                    id="next-of-kin"
                    v-model="nextOfKin"
                    type="text"
                    class=""
                  />
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="next-of-kin-relationship">Kin Relationship*
                  </label>
                  <input
                    ref="nextOfKinRelationshipInput"
                    v-model="nextOfKinRelationship"
                    type="text"
                    id="next-of-kin-relationship"
                    placeholder=""
                    class=""
                  />
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="next-of-kin-contacts">Kin Contacts* </label>
                  <input
                    ref="nextOfKinContactsInput"
                    v-model="nextOfKinContacts"
                    type="text"
                    id="next-of-kin-contacts"
                    placeholder=""
                    class=""
                  />
                </div>
              </div>
            </div>
          </section>

          <!-- Education -->
          <section class="row mt-2">
            <div class="col-12">
              <h4>Education</h4>
              <hr />
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-12 mb-2">
                  <el-radio
                    :label="true"
                    v-model="isLocalStudent"
                  >
                    Local Student
                  </el-radio>
                  <el-radio
                    :label="false"
                    v-model="isLocalStudent"
                  >
                    International Students</el-radio>
                </div>
              </div>
              <div
                v-show="isLocalStudent"
                class="row"
              >
                <div class="col-12 col-md-4 mb-3">
                  <label for="o-level-year">O'level Year</label>
                  <el-date-picker
                    ref="oLevelYearInput"
                    class="w-100"
                    id="o-level-year"
                    v-model="oLevelYear"
                    format="yyyy"
                    value-format="yyyy"
                    type="year"
                    placeholder="YYYY"
                    :picker-options="pickerYearBefore"
                  >
                  </el-date-picker>
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="o-level-school">O'level School</label>
                  <input
                    ref="oLevelSchoolInput"
                    v-model="oLevelSchool"
                    type="text"
                    id="o-level-school"
                    placeholder=""
                    class=""
                  />
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="o-level-index-number">O'level Index No.</label>
                  <input
                    ref="oLevelIndexNumberInput"
                    type="text"
                    id="o-level-index-number"
                    v-model="oLevelIndexNumber"
                    class=""
                  />
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="a-level-year">A'level Year</label>
                  <el-date-picker
                    ref="aLevelYearInput"
                    class="w-100"
                    id="a-level-year"
                    v-model="aLevelYear"
                    format="yyyy"
                    value-format="yyyy"
                    type="year"
                    placeholder="YYYY"
                    :picker-options="pickerYearBefore"
                  >
                  </el-date-picker>
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="a-level-school">A'level School</label>
                  <input
                    ref="aLevelSchoolInput"
                    v-model="aLevelSchool"
                    type="text"
                    id="a-level-school"
                    placeholder=""
                    class=""
                  />
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label for="a-level-index-number">A'level Index No.</label>
                  <input
                    ref="aLevelIndexNumberInput"
                    type="text"
                    id="a-level-index-number"
                    v-model="aLevelIndexNumber"
                    class=""
                  />
                </div>
              </div>
            </div>
          </section>

          <!-- Documents -->
          <section class="row mt-2">
            <div class="col-12">
              <h4>Documents added</h4>
              <hr />
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <div class="row justify-content-start">
                    <div
                      class="col col-auto"
                      v-for="(doc, index) in existingDocuments"
                      :key="index + '2'"
                    >
                      <div class="document-card">
                        <div
                          @click="onRemoveExistingDocument(index)"
                          class="remove-document-btn d-flex justify-content-center align-items-center"
                        >
                          <i class="el-icon-delete"></i>
                        </div>
                        <div class="list-icon">
                          <i class="el-icon-document"></i>
                        </div>
                        <div class="list-content doc-title-trim">
                          {{ doc.title }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="col col-auto"
                      v-for="(doc, index) in addedDocuments"
                      :key="index"
                    >
                      <div class="document-card">
                        <div
                          :disabled="isBusy || isUploadingDocument[index]"
                          @click="onRemoveDocument(index)"
                          class="remove-document-btn d-flex justify-content-center align-items-center"
                        >
                          <i class="el-icon-delete"></i>
                        </div>

                        <div class="list-icon">
                          <i class="el-icon-document"></i>
                        </div>
                        <div class="list-content doc-title-trim">
                          {{ doc.title }}
                        </div>
                      </div>
                    </div>
                    <div class="col col-auto">
                      <el-button
                        class="z-depth-0 add-document-btn flex-column d-flex"
                        @click="showAddDocumentDialog"
                      >
                        Add document <i class="el-icon-plus"></i>
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
              <UploadDocumentDialog
                :is-dialog-open="isDialogOpen"
                @on-close-dialog="onCloseDialog"
                @on-add-document="onDocumentAdded"
              />
            </div>
          </section>

          <!-- Last section for the submit button  -->
          <section class="row mt-2">
            <div class="col-12">
              <el-button
                style="
                  background-color: var(--el-app-primary);
                  color: white;
                  font-size: 0.92em;
                "
                class="z-depth-0"
                :disabled="isBusy"
                @click="onSubmitEditOnlineApplicationForm"
              >
                Update Student
              </el-button>
            </div>
          </section>
        </div>
      </div>
    </div>
    <div
      class="container-fluid text-center"
      v-else
    >
      <div class="row">
        <div class="col p-5">
          <img
            src="../assets/img/page-not-found.png"
            style="max-width: 200px; height: auto; width: auto"
          />
          <h6 class="mt-2">
            Student had been removed or deleted, <br />
            no record found.
          </h6>
          <button
            @click="backToOnlineApplicationsTable"
            class="btn btn-sm btn-primary z-depth-0"
          >
            Back to online Admissions Table
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumbComponent from "../components/bread-crumb-component.vue";
import _countries from "../helpers/countries";
import _nationalities from "../helpers/nationalities";
import UploadStudentOfficialPictureDialogComponent from "../components/dialogs/upload-student-official-picture-dialog-component.vue";
import UploadDocumentDialog from "../components/online-applications/UploadDocumentDialog.vue";
import { getFileExtension } from "../helpers/mimeTypesToExtension";
import { isBefore } from "date-fns";
import getMonthName from "../helpers/getMonthName";
const PHONE_NUMBER_VALIDATION_TEXT =
  /(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
export default {
  name: "EditStudent",
  components: {
    BreadCrumbComponent,
    UploadStudentOfficialPictureDialogComponent,
    UploadDocumentDialog,
  },
  data() {
    return {
      // fullScreen loader
      fullScreenLoaderText: "Uploading Documents",
      isSearching: false,
      isDialogOpen: false, // handle the opening and closing of the upload dialog

      countries: _countries,
      nationalities: _nationalities,
      isBusy: false,
      isBusyPage: false,
      isBusyRequestingUploadUrls: false,
      isBusyUploadingToAws: false,
      isValidated: false,
      isAdmitted: false,
      pageDoesNotExist: false,

      billingSystems: [],
      sessions: [],

      addedDocuments: [
        // { fileName: "", file: "", uploadLocation: "", fileSize: "" },
      ],
      studentOfficialPhotos: [],
      existingStudentOfficialPhotos: [],
      isDocumentUploadComplete: false,
      documentUploadProgress: {
        // [index]: value  /
      },
      isUploadingDocument: {
        // docIndex: true / false
      },
      fileList: [],
      doc: {
        title: "",
        fileSize: null,
        fileName: null,
        file: null,
      },
      onlineApplicationId: "",
      title: "", // select options
      firstName: "",
      lastName: "",
      gender: "", // select input,
      dob: "", // date picker,
      phoneNumber: "", //
      email: "",
      billingSystem: "",
      sessionId: "",
      sponsorName: "",
      sponsorContact: "",
      nationality: "",
      academicYear: "",

      // Bio Data fields
      maritalStatus: "", // select
      religion: "",
      spouseName: "", // enables the spouse fields
      spouseOccupation: "",
      spouseContacts: "",
      disabilities: "",
      physicalAddress: "",
      residenceCountry: "", // select from countries list
      homeDistrict: "",
      occupation: "",
      nextOfKin: "",
      nextOfKinRelationship: "",
      nextOfKinContacts: "",

      // Education
      isLocalStudent: true, //
      oLevelYear: "", // applicable if local,
      oLevelSchool: "", // applicable if local,
      oLevelIndexNumber: "", // applicable if local,
      aLevelYear: "", // applicable if local,
      aLevelSchool: "", // applicable if local,
      aLevelIndexNumber: "", // applicable if local
      isInternationalStudent: false, //

      // uploaded documents
      uploadedDocuments: [
        /** LC! Letter, National Id Card Photocopy, Birth Certificate */
      ], // all the updated documents
      documentsToUpload: [],
      existingDocuments: [],
      intakeBatchId: "",
      courseIntakeBatches: [],
      createdAt: "",
      course: {},

      // pickerYearBefore:{
      //     disabledDate: (year) => {
      //     return year > new Date().getFullYear()
      // },
      pickerYearBefore: {
        disabledDate: (year) => {
          return year > Date.now();
        },
      },
      entryMethod: "",
      entryTypes: [
        {
          value: "DIRECT",
          label: "Direct",
        },
        {
          value: "CERTIFICATE",
          label: "Certificate",
        },
        {
          value: "DIPLOMA",
          label: "Diploma",
        },
        {
          value: "MATURE_AGE",
          label: "Mature Age",
        },
      ],
      academicYears: [],
    };
  },

  computed: {
    isDocumentValid() {
      if (!this.doc.title) {
        return false;
      }

      if (this.doc.file === null) {
        return false;
      }

      return true;
    },
    isSingle() {
      if (this.maritalStatus === "Single") {
        return true;
      } else return false;
    },
  },

  watch: {
    maritalStatus(newValue) {
      if (newValue === "Single") {
        (this.spouseName = ""),
          (this.spouseOccupation = ""),
          (this.spouseContacts = "");
      }
    },
    isLocalStudent(val) {
      if (val === false) {
        this.oLevelYear = "";
        this.oLevelSchool = "";
        this.oLevelIndexNumber = "";
        this.aLevelYear = "";
        this.aLevelSchool = "";
        this.aLevelIndexNumber = "";
      }
    },
  },

  async mounted() {
    // check if the intake exists and then let them continue.
    // get the billing systems and sessions on page load
    this.onlineApplicationId = this.$route.params.onlineApplicationId;
    await this.getOnlineApplicationDetails();
    await this.getSessionsAndBillingSystems();
  },

  methods: {
    async getOnlineApplicationDetails() {
      if (this.isBusyPage) {
        return;
      }
      this.fullScreenLoader = this.$loading({
        lock: true,
        text: this.fullScreenLoaderText,
        spinner: "el-icon-loading",
        background: "rgba(0%, 0%, 0%, 0.7)",
      });
      // make the form busy
      this.isBusyPage = true;

      try {
        document.querySelector(".el-loading-text").innerText = `Please wait...`;

        const response = await this.$http.get(
          `/website/online-web-applications/fetch-one-for-edit/${this.onlineApplicationId}`
        );
        if (
          response.data.success &&
          response.data.message ==
            "ONLINE WEBSITE APPLICATION FETCHED SUCCESSFULLY"
        ) {
          const onlineApplication = response.data.online_application;
          this.intakeBatchId = onlineApplication.course_intake_batch_id || "";
          this.title = onlineApplication.title || "";
          this.firstName = onlineApplication.first_name || "";
          this.lastName = onlineApplication.last_name || "";
          this.gender = onlineApplication.gender || "";
          this.nationality = onlineApplication.nationality || "";
          this.dob = onlineApplication.dob || "";
          this.phoneNumber = onlineApplication.phone_number || "";
          this.email = onlineApplication.email || "";
          this.sponsorName = onlineApplication.sponsor_name || "";
          this.sponsorContact = onlineApplication.sponsor_contact || "";
          this.maritalStatus = onlineApplication.marital_status || "";
          this.religion = onlineApplication.religion || "";
          this.spouseName = onlineApplication.spouse_name || "";
          this.spouseOccupation = onlineApplication.spouse_occupation || "";
          this.spouseContacts = onlineApplication.spouse_contacts || "";
          this.disabilities = onlineApplication.disabilities || "";
          this.physicalAddress = onlineApplication.physical_address || "";
          this.residenceCountry = onlineApplication.residence_country || "";
          this.homeDistrict = onlineApplication.home_district || "";
          this.occupation = onlineApplication.occupation || "";
          this.nextOfKin = onlineApplication.next_of_kin || "";
          this.nextOfKinRelationship = onlineApplication.kin_relationship || "";
          this.nextOfKinContacts = onlineApplication.kin_contacts || "";
          // this.isInternationalStudent = onlineApplication.is_international_student || false;
          this.isLocalStudent = onlineApplication.is_local_student || false;
          this.oLevelSchool = onlineApplication.o_level_school_name || "";
          this.oLevelYear = onlineApplication.o_level_school_year || "";
          this.oLevelIndexNumber =
            onlineApplication.o_level_school_index_no || "";
          this.aLevelSchool = onlineApplication.a_level_school_name || "";
          this.aLevelYear = onlineApplication.a_level_school_year || "";
          this.aLevelIndexNumber =
            onlineApplication.a_level_school_index_no || "";
          this.sessionId = onlineApplication.student_session_id || "";
          this.academicYear =
            onlineApplication.academic_year_configuration_id || "";
          this.entryMethod = onlineApplication.entry_method || "";
          this.billingSystem = onlineApplication.billing_system_id || "";
          this.websiteApplicationId =
            onlineApplication.website_online_application_id || "";
          const batches = response.data.course_intake_batch ?? "";
          this.courseIntakeBatches.push(batches);
          // this.courseIntakeBatches.push(() => {
          //   if(!response.data.course_intake_batch){
          //     return response.data.course_intake_batch
          //   }else return "";
          // });
          this.existingDocuments = onlineApplication.bio_documents
            .map((doc) => {
              if (
                doc.is_circular_official_picture == true ||
                doc.is_square_official_picture == true
              ) {
                return false;
              }

              return {
                path_key: doc.file_path_key,
                path_url: doc.file_path_url,
                student_bio_document_id: doc.student_bio_document_id,
                title: doc.title,
              };
            })
            .filter((doc) => doc !== false);

          this.existingStudentOfficialPhotos = onlineApplication.bio_documents
            .map((doc) => {
              if (
                doc.is_circular_official_picture == true ||
                doc.is_square_official_picture == true
              ) {
                return {
                  path_key: doc.file_path_key,
                  path_url: doc.file_path_url,
                  student_bio_document_id: doc.student_bio_document_id,
                  title: doc.title,
                  is_circular_official_picture:
                    doc.is_circular_official_picture,
                  is_square_official_picture: doc.is_square_official_picture,
                };
              }
              return false;
            })
            .filter((photo) => photo !== false);
          return;
        }
        this.$rollbar.warning(
          "ADMIN FRONT END: Unexpected API response while getting server response",
          response.data,
          response
        );
        throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }

        console.error(error);
        this.showFailedMessage(
          "Unable to get Online Application details",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.fullScreenLoader?.close();
        this.isBusyPage = false;
      }
    },

    async onSearchCourseIntakeBatches(searchTerm) {
      if (searchTerm !== "" && searchTerm.length >= 3) {
        this.isSearching = true;
        try {
          let request = await this.$http.get(
            `course-intake-batches/search?search_term=${searchTerm}`
          );
          if (
            request.data.success &&
            request.data.message == "COURSE INTAKE BATCHES FETCHED SUCCESSFULLY"
          ) {
            this.courseIntakeBatches = request.data.intakes;
          } else {
            this.$rollbar.warning(
              "ADMIN FRONT END: Unexpected API response while getting server response",
              request.data,
              request
            );
            throw "UNEXPECTED_RESPONSE";
          }
        } catch (error) {
          if (error.message == "Network Error") {
            return this.showFailedMessage(
              "Connection failed",
              "A network error occurred, please try again."
            );
          }
          // console.log(error);
          this.showFailedMessage(
            "Searching Failed",
            "Unable to search Intakes now, Please try again later"
          );
        } finally {
          this.isSearching = false;
        }
      }
    },

    validateAndShowErrors() {
      const EMAIL_REGEXP =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.isValidated = false;
      // ensure the intakeBatch is selected
      if (!this.intakeBatchId) {
        this.$refs.intakeBatchInput.focus();
        this.showWarningMessage(
          "Intake Batch not selected",
          "Please select an Intake Batch"
        );
        return false;
      }

      if (!(this.title.length < 255 && this.title.length >= 2)) {
        this.$refs.titleInput.focus();
        this.showWarningMessage(
          "Invalid Title",
          "Please enter a valid title with less than 255 characters"
        );
        return false;
      }

      // Validate the first name
      if (!this.firstName || this.firstName.length > 80) {
        this.$refs.firstNameInput.focus();
        this.showWarningMessage(
          "Invalid First Name",
          "Please enter a valid first name with less than 80 characters"
        );
        return false;
      }

      // Validate the last name
      if (!this.lastName || this.lastName.length > 80) {
        this.$refs.lastNameInput.focus();
        this.showWarningMessage(
          "Invalid Last Name",
          "Please enter a valid last name with less than 80 characters"
        );
        return false;
      }

      // Ensure the gender is provided
      if (!this.gender) {
        this.$refs.genderInput.focus();
        this.showWarningMessage("Gender required", "Please choose a gender");
        return false;
      }

      // ensure that nationality is provided
      if (!this.nationality) {
        this.$refs.nationalityInput.focus();
        this.showWarningMessage(
          "Nationality required",
          "Please select a nationality"
        );
        return false;
      }

      // ensure the academic year is provided
      if (!this.academicYear) {
        this.$refs.academicYearInput.focus();
        this.showWarningMessage(
          "Academic year required",
          "Please Choose an Academic year"
        );
        return false;
      }

      if (this.dob) {
        let today = new Date(Date.now());
        let beforeDate = new Date(today.setFullYear(today.getFullYear() - 10));
        if (isBefore(beforeDate, new Date(Date.parse(this.dob)))) {
          this.showWarningMessage(
            "Invalid date",
            "Date should be greater than 10 years ago"
          );
          this.$refs.dobInput.focus();
          return false;
        }
      }

      // phone number
      for (let index = 0; index < this.phoneNumber.split(",").length; index++) {
        if (
          !PHONE_NUMBER_VALIDATION_TEXT.test(this.phoneNumber.split(",")[index])
        ) {
          this.$refs.phoneNumberInput.focus();
          this.showWarningMessage(
            "Invalid Phone number",
            "Please enter a valid phone number or numbers separated by spaces eg. +256700000000,+29000000000"
          );
          return false;
        }
      }

      if (!this.email || this.email.length > 255) {
        this.$refs.emailInput.focus();
        this.showWarningMessage(
          "Invalid Email",
          "Please enter a valid email with less than 255 characters"
        );
        return false;
      }

      // Check and validate the email format too
      if (!EMAIL_REGEXP.test(this.email)) {
        this.$refs.emailInput.focus();
        this.showWarningMessage(
          "Invalid Email",
          "Please enter a valid email address in the format email@example.com"
        );
        return false;
      }

      if (!this.billingSystem) {
        this.$refs.billingSystemInput.focus();
        this.showWarningMessage(
          "Select Billing system",
          "A billing system should be selected for the student"
        );
        return false;
      }

      if (!this.entryMethod) {
        this.$refs.entryTypesInput.focus();
        this.showWarningMessage(
          "Missing Entry Type",
          "Please select and Entry Method for the student"
        );
        return false;
      }
      // ensure a session is selected
      if (!this.sessionId) {
        this.$refs.sessionIdInput.focus();
        this.showWarningMessage("Session required", "Please select a session");
        return false;
      }

      // If sponsor name is provided, ensure its within the valid length
      if (this.sponsorName && this.sponsorName.length > 255) {
        this.$refs.sponsorNameInput.focus();
        this.showWarningMessage(
          "Invalid Sponsor Name",
          "Please enter a valid sponsor name with less than 255 characters"
        );
        return false;
      }

      // PHONE NUMBER SLICE THE PHONE NUMBERS with a coma
      if (this.sponsorContact.length) {
        for (
          let index = 0;
          index < this.sponsorContact.split(",").length;
          index++
        ) {
          if (
            !PHONE_NUMBER_VALIDATION_TEXT.test(
              this.sponsorContact.split(",")[index]
            )
          ) {
            this.$refs.sponsorContactInput.focus();
            this.showWarningMessage(
              "Invalid Phone number",
              "Please enter a valid phone number or numbers separated by spaces eg. +256700000000,+29000000000"
            );
            return false;
          }
        }
      }

      if (!this.religion || this.religion.length > 100) {
        this.$refs.religionInput.focus();
        this.showWarningMessage(
          "Invalid Religion",
          "Please enter a valid religion with less than 100 characters"
        );
        return false;
      }

      if (this.maritalStatus == "Married") {
        // Ensure the spouse name is provided and is of valid length
        if (!this.spouseName || this.spouseName.length > 255) {
          this.$refs.spouseNameInput.focus();
          this.showWarningMessage(
            "Invalid Spouse Name",
            "Please enter a valid spouse name with less than 255 characters"
          );
          return false;
        }

        if (this.spouseOccupation.length < 3) {
          this.showWarningMessage(
            "Invalid Spouse Occupation",
            "Please provide a spouse occupation"
          );
          this.$refs.spouseOccupationInput.focus();
          return false;
        }

        if (this.spouseContacts.length < 10) {
          this.$refs.spouseContactsInput.focus();
          this.showWarningMessage(
            "Invalid Spouse contacts",
            "Please enter a valid phone number or numbers separated by spaces eg. +256700000000,078000000000"
          );
          return false;
        } else {
          for (
            let index = 0;
            index < this.spouseContacts.split(",").length;
            index++
          ) {
            if (
              !PHONE_NUMBER_VALIDATION_TEXT.test(
                this.spouseContacts.split(",")[index]
              )
            ) {
              this.$refs.spouseContactsInput.focus();
              this.showWarningMessage(
                "Invalid Spouse contacts",
                "Please enter a valid phone number or numbers separated by spaces eg. +256700000000,078000000000"
              );
              return false;
            }
          }
        }
      }

      if (this.disabilities.length > 0) {
        if (!(this.disabilities.length > 3 && this.disabilities.length < 250)) {
          this.$refs.disabilitiesInput.focus();
          this.showWarningMessage(
            "Invalid Disabilities",
            "Maximum length should be 255 and min length 3"
          );
          return false;
        }
      }

      if (this.physicalAddress.length && this.physicalAddress.length > 255) {
        this.$refs.physicalAddressInput.focus();
        this.showWarningMessage(
          "Invalid physical address",
          "Physical address should have a maximum length of 255 characters"
        );
        return false;
      }

      if (!/^[a-zA-Z ]*$/.test(this.homeDistrict)) {
        //   homeDistrict can contain only alphabets and space
        this.$refs.homeDistrictInput.focus();
        this.showWarningMessage(
          "Invalid Home District",
          "Home district should contain only alphabetical characters"
        );
        return false;
      }
      if (this.occupation.length > 255) {
        this.$refs.occupationInput.focus();
        this.showWarningMessage(
          "Invalid Occupation",
          "Please enter a valid occupation, occupation should be less that 255 characters"
        );
        return false;
      }
      // Ensure next of kin name is provided and is of valid length
      if (!this.nextOfKin || this.nextOfKin.length > 255) {
        this.$refs.nextOfKinInput.focus();
        this.showWarningMessage(
          "Invalid Name",
          "Please enter a valid next of kin name with less than 255 characters"
        );
        return false;
      }
      if (
        this.nextOfKin &&
        (this.nextOfKinRelationship.length < 2 ||
          this.nextOfKinRelationship.length > 255)
      ) {
        this.$refs.nextOfKinRelationshipInput.focus();
        this.showWarningMessage(
          "Invalid next of kin relationship",
          "Please enter a valid next of kin relationship like, brother, mother, uncle etc."
        );
        return false;
      }
      for (
        let index = 0;
        index < this.nextOfKinContacts.split(",").length;
        index++
      ) {
        if (
          this.nextOfKin &&
          !PHONE_NUMBER_VALIDATION_TEXT.test(
            this.nextOfKinContacts.split(",")[index]
          )
        ) {
          this.$refs.nextOfKinContactsInput.focus();
          this.showWarningMessage(
            "Invalid next of kin contacts",
            "Please enter a valid phone number or numbers separated by spaces eg. +256700000000,+29000000000"
          );
          return false;
        }
      }
      if (this.nextOfKin && !this.nextOfKinContacts) {
        this.$refs.nextOfKinContactsInput.focus();
        this.showWarningMessage(
          "Next of kin contacts required",
          "Please enter a valid phone number or numbers separated by spaces eg. +256700000000,+29000000000"
        );
        return false;
      }

      //If isLocalStudent is not either true or false, then show error to the user
      if (this.isLocalStudent !== true && this.isLocalStudent !== false) {
        this.showWarningMessage(
          "Required Field",
          "Please choose whether the Student is a Local Student or International Student"
        );
        return false;
      }
      if (this.isLocalStudent) {
        if (!this.oLevelYear) {
          this.$refs.oLevelYearInput.focus();
          this.showWarningMessage(
            "Required Field",
            "Please enter the year you sat for your O'Level exams"
          );
          return false;
        }

        if (!this.oLevelIndexNumber || this.oLevelIndexNumber.length > 255) {
          this.$refs.oLevelIndexNumberInput.focus();
          this.showWarningMessage(
            "Required Field",
            "Please enter your O'Level index number"
          );
          return false;
        }

        if (!this.oLevelSchool || this.oLevelSchool.length > 255) {
          this.$refs.oLevelSchoolInput.focus();
          this.showWarningMessage(
            "Required Field",
            "Please enter the school you went to for your O'Level."
          );
          return false;
        }

        // Now validate A Level details if provided

        if (this.aLevelIndexNumber && this.aLevelIndexNumber.length > 255) {
          this.$refs.aLevelIndexNumberInput.focus();
          this.showWarningMessage(
            "Required Field",
            "Please enter your A'Level index number"
          );
          return false;
        }

        if (this.aLevelSchool && this.aLevelSchool.length > 255) {
          this.$refs.aLevelSchoolInput.focus();
          this.showWarningMessage(
            "Required Field",
            "Please enter the school you went to for your A'Level."
          );
          return false;
        }
      }

      // allow user to submit form without new added docs if their are some existing docs
      if (
        this.addedDocuments.length === 0 &&
        this.existingDocuments.length === 0
      ) {
        this.showWarningMessage(
          "No Documents",
          "Please add at least one document"
        );
        return false;
      }

      // Ensure the user has not chosen more than 5 documents
      if (this.addedDocuments.length > 5) {
        this.showWarningMessage(
          "Too Many Documents",
          "You can not select more than 5 documents"
        );
        return false;
      }

      return true;
    },

    toMonthName(monthNum) {
      return getMonthName(monthNum);
    },
    // go back to students table if the ToOnlineApplicationsTable id is invalid
    backToOnlineApplicationsTable() {
      this.$router.push({ name: "OnlineApplications" });
    },

    onCloseDialog() {
      this.isDialogOpen = false;
    },
    showAddDocumentDialog() {
      this.isDialogOpen = true;
    },
    onDocumentAdded(doc) {
      this.addedDocuments.push(doc);
      this.onCloseDialog();
    },

    // get the images from the resize component
    onFinishedCroppingStudentPhotos({ circularImage, squareImage }) {
      // get teh raw image from the url image or base64image
      function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        let byteString;
        if (dataURI.split(",")[0].indexOf("base64") >= 0) {
          byteString = atob(dataURI.split(",")[1]);
        } else {
          byteString = unescape(dataURI.split(",")[1]);
        }

        // separate out the mime component
        let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

        // write the bytes of the string to a typed array
        let ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
      }

      this.existingStudentOfficialPhotos = [];
      this.studentOfficialPhotos = [
        {
          file: dataURItoBlob(circularImage),
          fileName:
            "Circular Student Image." +
            getFileExtension(dataURItoBlob(circularImage).type),
          title: "Circular Student Image",
          fileSize: dataURItoBlob(circularImage).size,
          isCircularImage: true,
        },
        {
          title: "Square Student Image",
          file: dataURItoBlob(squareImage),
          fileName:
            "Square Student Image." +
            getFileExtension(dataURItoBlob(squareImage).type),
          fileSize: dataURItoBlob(squareImage).size,
          isSquareImage: true,
        },
      ];
    },

    onRemoveDocument(index_) {
      this.addedDocuments = this.addedDocuments.filter(
        (doc, index) => index !== index_
      );
    },

    onRemoveExistingDocument(index_) {
      this.existingDocuments = this.existingDocuments.filter(
        (doc, index) => index !== index_
      );
    },

    // load sessions and billing systems
    async getSessionsAndBillingSystems() {
      // Load the billing systems and the sessions
      try {
        this.isBusyPage = true;
        const response = await this.$http.get(
          "/students/admissions/get-sessions-and-billing-systems"
        );

        // check if all has been successful
        if (
          response.data.success &&
          response.data.message ==
            "SESSIONS AND BILLING SYSTEMS FETCHED SUCCESSFULLY"
        ) {
          this.billingSystems = response.data.billing_systems;
          this.sessions = response.data.sessions;
          this.academicYears = response.data.academic_years;
          this.isBusyPage = false;
        } else {
          // Handle any other unexpected success response
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            response.data,
            response
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.loadingError = true;
        this.showFailedMessage(
          "Unable to load sessions and billing systems",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    async onSubmitEditOnlineApplicationForm() {
      try {
        // validate the form
        if (!this.validateAndShowErrors()) {
          return;
        }

        // make the form busy
        this.isBusy = true;

        // Full screen loader covering the entire screen
        this.fullScreenLoader = this.$loading({
          lock: true,
          text: this.fullScreenLoaderText,
          spinner: "el-icon-loading",
          background: "rgba(0%, 0%, 0%, 0.7)",
        });
        // request for file upload urls if there are new documents to be uploaded
        if (
          this.addedDocuments.length > 0 ||
          this.studentOfficialPhotos.length > 0
        ) {
          const response = await this.requestForUploadUrls();
          // if the response is successful, upload the files
          if (response.success) {
            await this.uploadToAWS(response.upload_urls);
          } else throw "UNEXPECTED_RESPONSE";
        }
        // update the online application
        await this.updateOnlineApplication();
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.showFailedMessage(
          "Unable to Save Changes",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.fullScreenLoader?.close();
        this.isBusy = false;
      }
    },
    /**
     * Request for upload urls from the sever before upload
     */
    async requestForUploadUrls() {
      try {
        this.documentsToUpload = [
          ...this.addedDocuments.map((doc) => {
            return {
              title: doc.title,
              file_name: doc.fileName,
              file_size: doc.fileSize,
              file: doc.file,
            };
          }),
          ...this.studentOfficialPhotos.map((photo) => {
            return {
              title: photo.title,
              file_name: photo.fileName,
              file_size: photo.fileSize,
              file: photo.file,
              is_circular_official_picture: photo.isCircularImage,
              is_square_official_picture: photo.isSquareImage,
            };
          }),
        ];
        const response = await this.$http.post(
          "website/public/online-web-applications/request-upload-urls",
          { chosen_files_info: this.documentsToUpload }
        );

        if (
          response.data.success &&
          response.data.message === "UPLOAD URLS GENERATED"
        ) {
          this.showSuccessMessage(
            "Uploading files started",
            "File upload is in progress"
          );
          return {
            success: true,
            upload_urls: response.data.upload_urls,
          };
        }
      } catch (error) {
        //
        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.showFailedMessage(
          "Unable to add document",
          "An unexpected Error occurred, please try again"
        );
      }
    },

    /**
     * Upload the documents to the server
     */
    async uploadToAWS(uploadUrls) {
      try {
        for (let index = 0; index < this.documentsToUpload.length; index++) {
          const { url, fields } = uploadUrls[index].upload_url;

          // add the form form to form data
          const formData = new FormData();
          Object.entries(fields).forEach(([key, value]) => {
            formData.append(key, value);
          });
          formData.append("file", this.documentsToUpload[index].file);

          // send the file to the aws server
          const response = await this.$http.post(url, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: " ",
            },
            onUploadProgress: (progressEvent) => {
              this.onUploadProgress({ index, progressEvent });
            },
          });

          // check if the response is 204 aws does not return anything apart from the header for the location of the file.
          if (response.status === 204) {
            this.documentsToUpload[index].path_key = fields.key;
            this.documentsToUpload[index].path_url = decodeURIComponent(
              response.headers.location
            );
            continue;
          } else {
            throw new Error("Error uploading file");
          }
        }

        return true;
      } catch (error) {
        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        return this.showFailedMessage(
          "Upload failed",
          "An error occurred while uploading your documents, please try again."
        );
      } finally {
        this.isBusySavingDocumentsUrls = false;
      }
    },
    /**
     * Submit the form to the server
     */
    async updateOnlineApplication() {
      this.isBusy = true;
      try {
        // Used to update the loader
        document.querySelector(".el-loading-text").innerText = `Saving Changes`;

        // check the existing student official photos
        const response = await this.$http.patch(
          `website/online-web-applications/edit-one/${this.onlineApplicationId}`,
          {
            title: this.title,
            first_name: this.firstName,
            last_name: this.lastName,
            dob: this.dob,
            sponsor_name: this.sponsorName,
            sponsor_contact: this.sponsorContact,
            nationality: this.nationality,
            phone_number: this.phoneNumber,
            email: this.email,
            gender: this.gender,
            student_session_id: this.sessionId,
            course_intake_batch_id: this.intakeBatchId,
            billing_system_id: this.billingSystem,
            academic_year_configuration_id: this.academicYear,
            entry_method: this.entryMethod,
            bio_data: {
              marital_status: this.maritalStatus,
              religion: this.religion,
              spouse_name: this.spouseName,
              spouse_occupation: this.spouseOccupation,
              spouse_contacts: this.spouseContacts,
              disabilities: this.disabilities,
              physical_address: this.physicalAddress,
              residence_country: this.residenceCountry,
              home_district: this.homeDistrict,
              occupation: this.occupation || "N/A",
              next_of_kin: this.nextOfKin,
              kin_relationship: this.nextOfKinRelationship,
              kin_contacts: this.nextOfKinContacts,
            },
            education_background: {
              is_local_student: this.isLocalStudent,
              o_level_school_name: this.oLevelSchool,
              o_level_school_year: this.oLevelYear,
              o_level_school_index_no: this.oLevelIndexNumber,
              a_level_school_name: this.aLevelSchool,
              a_level_school_year: this.aLevelYear,
              a_level_school_index_no: this.aLevelIndexNumber,
            },
            student_documents: [
              ...this.documentsToUpload,
              ...this.existingDocuments,
              ...this.existingStudentOfficialPhotos,
            ],
          }
        );
        // eslint-disable-next-line no-unreachable
        if (
          response.data.success &&
          response.data.message == "WEB APPLICATION UPDATED SUCCESSFULLY"
        ) {
          this.$router.push("/dashboard/online-applications");
          return this.showSuccessMessage(
            "Updated Successful",
            "Online Application has been updated successfully"
          );
        }
        // eslint-disable-next-line no-unreachable
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }

        this.showFailedMessage(
          "Update failed",
          "An unexpected Error occurred while updating student, please try again"
        );
        // eslint-disable-next-line no-unreachable
      } finally {
        this.fullScreenLoader?.close();
        this.isBusy = false;
      }
    },

    /**
     * Show a upload progress
     */
    onUploadProgress({ index, progressEvent }) {
      const percentCompleted = Math.min(
        100,
        parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
      );

      document.querySelector(".el-loading-text").innerText = `Uploading file ${
        index + 1
      } of ${this.documentsToUpload.length} (${percentCompleted}%)`;
    },
  },
};
</script>

<style scoped>
input:disabled {
  background: #f5f7fa !important;
  cursor: not-allowed;
  opacity: 0.5;
}
h4 {
  font-size: 1rem;
  font-weight: 600;
}
.vh100 {
  height: vh100;
}
.admitted-img {
  height: 150px;
  width: auto;
}
.add-document-btn,
.document-card {
  width: 150px;
  height: 150px;
  border: solid 1px var(--el-app-primary);
  color: var(--el-app-primary);
  font-size: 0.92em;
  background: transparent;
  transition: 0.3s ease;
  border-radius: 10px;
  justify-content: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.document-card {
  border: dotted 2px #558899;
  border-spacing: 4px;
}

.remove-document-btn {
  width: 30px;
  height: 30px;
  background: #444;
  color: aliceblue;
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 15px;
  transition: 0.3s ease;
}
.remove-document-btn:hover {
  transform: scale(0.9);
  background: #fff;
  border: solid 1px red;
  color: red;
  transition: 0.3s ease;
}

.add-document-btn:hover {
  transition: 0.3s ease;
  transform: scale(0.9);
}
.is-upload-document-progress {
  position: absolute;
  display: none;
  z-index: 9999999999;
  height: 80%;
  width: 80%;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  background-color: rgba(100%, 100%, 100%, 0.6);
  backdrop-filter: blur(2px);
}
input[type="text"],
input[type="email"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}
</style>

<style>
.el-loading-spinner .el-icon-loading {
  color: #fff !important;
  font-size: 2rem;
}
.el-loading-spinner .el-loading-text {
  color: #fff !important;
}
</style>
