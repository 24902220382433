const knownMimeTypes = {
  "text/html": ".html",
  "text/css": "css",
  "text/xml": "xml",
  "image/gif": "gif",
  "image/jpeg": "jpegjpg",
  "application/x-javascript": "js",
  "application/atom+xml": "atom",
  "application/rss+xml": "rss",
  "text/mathml": "mml",
  "text/plain": "txt",
  "text/vnd.sun.j2me.app-descriptor": "jad",
  "text/vnd.wap.wml": "wml",
  "text/x-component": "htc",
  "image/png": "png",
  "image/tiff": "tiff",
  "image/vnd.wap.wbmp": "wbmp",
  "image/x-icon": "ico",
  "image/x-jng": "jng",
  "image/x-ms-bmp": "bmp",
  "image/svg+xml": "svg",
  "image/webp": "webp",
  "application/java-archive": "jarwarear",
  "application/mac-binhex40": "hqx",
  "application/msword": "doc",
  "application/pdf": "pdf",
  "application/postscript": "psepsai",
  "application/rtf": "rtf",
  "application/vnd.ms-excel": "xls",
  "application/vnd.ms-powerpoint": "ppt",
  "application/vnd.wap.wmlc": "wmlc",
  "application/vnd.google-earth.kml+xml": "kml",
  "application/vnd.google-earth.kmz": "kmz",
  "application/x-7z-compressed": "7z",
  "application/x-cocoa": "cco",
  "application/x-java-archive-diff": "jardiff",
  "application/x-java-jnlp-file": "jnlp",
  "application/x-makeself": "run",
  "application/x-perl": "plpm",
  "application/x-pilot": "prcpdb",
  "application/x-rar-compressed": "rar",
  "application/x-redhat-package-manager": "rpm",
  "application/x-sea": "sea",
  "application/x-shockwave-flash": "swf",
  "application/x-stuffit": "sit",
  "application/x-tcl": "tcltk",
  "application/x-x509-ca-cert": "derpemcrt",
  "application/x-xpinstall": "xpi",
  "application/xhtml+xml": "xhtml",
  "application/zip": "zip",
  "application/octet-stream": "deb",
  "audio/midi": "midmidikar",
  "audio/mpeg": "mp3",
  "audio/ogg": "ogg",
  "audio/x-realaudio": "ra",
  "video/3gpp": "3gpp",
  "video/mpeg": "mpeg",
  "video/quicktime": "mov",
  "video/x-flv": "flv",
  "video/x-mng": "mng",
  "video/x-ms-asf": "asf",
  "video/x-ms-wmv": "wmv",
  "video/x-msvideo": "avi",
  "video/mp4": "m4vmp4",
};

export const getFileExtension = (mimeType) => {
  if (typeof knownMimeTypes[mimeType] !== "undefined") {
    return knownMimeTypes[mimeType];
  }

  return ""; // mime type not
};
